var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid wrapper-medium content-form"},[_c('div',{staticClass:"row"},[(!_vm.passwordResetTokenPresent)?_c('div',{staticClass:"col-12 col-md-6"},[_c('h1',{staticClass:"mb-3 anim-load1"},[_vm._v(" Reset your password ")]),_c('p',{staticClass:"lead anim-load2"},[_vm._v(" Enter your email address to reset your password. You'll receive an email with further instructions. ")])]):_vm._e(),(_vm.passwordResetTokenPresent)?_c('div',{staticClass:"col-12 col-md-6"},[_c('h1',{staticClass:"mb-3 anim-load1"},[_vm._v(" Create new password ")]),_c('p',{staticClass:"lead anim-load2"},[_vm._v(" Please enter new password that meets all listed requirements. ")])]):_vm._e(),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"card"},[_c('transition-group',{attrs:{"name":"form-item","tag":"div"}},[(!_vm.resetSent && !_vm.passwordResetTokenPresent)?_c('div',{key:"1",staticClass:"card-body"},[_c('ValidationObserver',{ref:"formReset"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.onRequestSubmit.apply(null, arguments)}}},[_c('div',{ref:"name",staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Your email")]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1890626387)})],1),_c('button',{staticClass:"btn btn-warning btn-lg btn-block",attrs:{"type":"submit"}},[_vm._v("Reset password")])])],1),_c('div',{staticClass:"text-center mt-4"},[_c('b-link',{staticClass:"btn btn-link",attrs:{"to":"/sign in/"}},[_vm._v("Sign in")])],1)],1):_vm._e(),(_vm.resetSent && !_vm.passwordResetTokenPresent)?_c('div',{key:"2",staticClass:"card-body"},[_c('h4',{staticClass:"text-lg-left mb-3"},[_vm._v("If your account exists, a password reset email is on its way to you.")]),_c('p',{staticClass:"card-text lead text-lg-left"},[_vm._v("Please check your email inbox and follow the instructions.")])]):_vm._e(),(_vm.passwordResetTokenPresent)?_c('div',{key:"3",staticClass:"card-body"},[_c('ValidationObserver',{ref:"formNewPass"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.onNewPasswordSubmit.apply(null, arguments)}}},[_c('div',{ref:"password",staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Create password")]),_c('ValidationProvider',{attrs:{"rules":"required|max:100","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:classes,attrs:{"type":"password","placeholder":"","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1332566338)})],1),_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v("Your password must:")]),_c('ul',{staticClass:"list-requirements"},[_c('li',{class:{'done': _vm.passwordValidation.charLength === true }},[_vm._v("be a minimum of 14 characters")]),_c('li',{class:{'done': _vm.passwordValidation.uppercase === true }},[_vm._v("contain uppercase characters")]),_c('li',{class:{'done': _vm.passwordValidation.lowercase === true }},[_vm._v("contain lowercase characters")]),_c('li',{class:{'done': _vm.passwordValidation.digit === true }},[_vm._v("contain at least one digit")]),_c('li',{class:{'done': _vm.passwordValidation.special === true }},[_vm._v("contain at least one special character")])])]),_c('div',{ref:"password",staticClass:"form-group mt-3 pb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Confirm password")]),_c('ValidationProvider',{attrs:{"rules":"required|password:@password","name":"passwordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"form-control",class:classes,attrs:{"type":"password","placeholder":"","name":"passwordConfirm"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2265312304)})],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.passwordValidation.errorDisplay},on:{"dismissed":function($event){_vm.passwordValidation.errorDisplay=false}}},[_c('p',{staticClass:"mb-0"},[_vm._v("Your password doesn't meet all requirements listed above.")])]),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.showDismissibleAlert},on:{"dismissed":function($event){_vm.showDismissibleAlert=false}}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]),_c('button',{staticClass:"btn btn-warning btn-lg btn-block",attrs:{"type":"submit"}},[_vm._v("Reset password")])],1)],1)],1):_vm._e()])],1)])])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }