<template>
<div>

  <div class="container-fluid wrapper-medium content-form">
    <div class="row">

      <div class="col-12 col-md-6" v-if="!passwordResetTokenPresent">
        <h1 class="mb-3 anim-load1">
          Reset your password
        </h1>
        <p class="lead anim-load2">
          Enter your email address to reset your password. You'll receive an email with further instructions.
        </p>
      </div>

      <div class="col-12 col-md-6" v-if="passwordResetTokenPresent">
        <h1 class="mb-3 anim-load1">
          Create new password
        </h1>
        <p class="lead anim-load2">
          Please enter new password that meets all listed requirements.
        </p>
      </div>

      <div class="col-12 col-md-6">
          <div class="card">
            <transition-group name="form-item" tag="div">

              <div class="card-body" v-if="!resetSent && !passwordResetTokenPresent" key="1">
                <ValidationObserver ref="formReset">
                  <b-form @submit.prevent="onRequestSubmit" class="mb-3">
                    <div class="form-group" ref="name">
                      <label for="email">Your email</label>
                      <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="email">
                        <input v-model="email" type="text" placeholder="" name="email" class="form-control" v-bind:class="classes">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <button type="submit" class="btn btn-warning btn-lg btn-block" >Reset password</button>
                  </b-form>
                </ValidationObserver>

                <div class="text-center mt-4">
                  <b-link to="/sign in/" class="btn btn-link">Sign in</b-link>
                </div>           
              </div>

              <div class="card-body" v-if="resetSent && !passwordResetTokenPresent" key="2">
                <h4 class="text-lg-left mb-3">If your account exists, a password reset email is on its way to you.</h4>
                <p class="card-text lead text-lg-left">Please check your email inbox and follow the instructions.</p>        
              </div>

              <div class="card-body" v-if="passwordResetTokenPresent" key="3">
                <ValidationObserver ref="formNewPass">
                  <b-form @submit.prevent="onNewPasswordSubmit" class="mb-3">

                    <div class="form-group" ref="password">
                      <label for="password">Create password</label>
                      <ValidationProvider v-slot="{classes, errors}" rules="required|max:100" name="password">
                        <input v-model="password" type="password" placeholder="" name="password" class="form-control" v-bind:class="classes">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div>
                      <p class="mb-2">Your password must:</p>
                      <ul class="list-requirements">
                        <li v-bind:class="{'done': passwordValidation.charLength === true }">be a minimum of 14 characters</li>
                        <li v-bind:class="{'done': passwordValidation.uppercase === true }">contain uppercase characters</li>
                        <li v-bind:class="{'done': passwordValidation.lowercase === true }">contain lowercase characters</li>
                        <li v-bind:class="{'done': passwordValidation.digit === true }">contain at least one digit</li>
                        <li v-bind:class="{'done': passwordValidation.special === true }">contain at least one special character</li>
                      </ul>
                    </div>

                    <div class="form-group mt-3 pb-3" ref="password">
                      <label for="email">Confirm password</label>
                      <ValidationProvider v-slot="{classes, errors}" rules="required|password:@password" name="passwordConfirm">
                        <input v-model="passwordConfirm" type="password" placeholder="" name="passwordConfirm" class="form-control" v-bind:class="classes">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <b-alert variant="danger"
                              dismissible
                              :show="passwordValidation.errorDisplay"
                              @dismissed="passwordValidation.errorDisplay=false">
                      <p class="mb-0">Your password doesn't meet all requirements listed above.</p>
                    </b-alert>

                    <b-alert variant="danger"
                              dismissible
                              :show="showDismissibleAlert"
                              @dismissed="showDismissibleAlert=false">
                      {{serverError}}
                    </b-alert>
                    <button type="submit" class="btn btn-warning btn-lg btn-block" >Reset password</button>

                  </b-form>
                </ValidationObserver>
              </div>

            </transition-group>

          </div>

      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>
export default {
  name: 'PasswordReset',
  data () {
    return {
      isProgress: false,
      passwordResetTokenPresent: false,
      passwordResetToken: null,
      email: null,
      resetSent: false,
      password: '',
      passwordConfirm: '',
      passwordValidation: {
        charLength: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special: false,
        errorDisplay: false,
        valid: false
      },
      serverError: "",
      showDismissibleAlert: false
    }
  },
  created() {
    this.$store.state.appSection = 'brochure'
    this.passwordResetToken = this.$route.query.code
    this.email = this.$route.query.email || ''
    this.email = this.email.replace(/\s/g, "+")
    this.$store.state.loadingProgress = false

    if (!this.passwordResetToken) { // check if page url contains parameters
      this.passwordResetTokenPresent = false
    } else {
      this.passwordResetTokenPresent = true
      this.$store.state.user = null
      localStorage.removeItem('jwt')
    }
  },
  methods: {
    onRequestSubmit () {
      this.$refs.formReset.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formReset.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formReset.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AuthForgotPassword,
            params: {
              email: this.email
            }
          })
          .then((response) => {
            console.log(response)
            this.resetSent = true  
          })
          .catch((error) => {
            console.error('Error', error)
            this.showError('Something went wrong')
          })
          .finally(() => {
            this.$store.state.loadingProgress = false
          })
        }
      });
    },

    onNewPasswordSubmit () {
      this.$refs.formNewPass.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formNewPass.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formNewPass.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          if (this.passwordValidation.uppercase && this.passwordValidation.lowercase && this.passwordValidation.digit && this.passwordValidation.special && this.passwordValidation.charLength) {
            this.passwordValidation.errorDisplay = false
            this.$store.state.loadingProgress = true

            this.$http({
              method: 'post', 
              url: this.$api.AuthResetPassword,
              params: {
                email: this.email,
                password: this.password,
                code: this.passwordResetToken
              }
            })
            .then((response) => {
              console.log(response)
              if (response.status === 200 && response.data.success === true) {
                this.alertMsgShow('Password changed successfully. You may now sign in.', true);
                this.$router.push('/sign-in/')
              } else {
                console.error('Error', response)
                if (response.data.message !== undefined) {
                  this.showError(response.data.message)
                } else {
                  this.showError('Something went wrong. Please try again later.')
                }
              }
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong')
            })
            .finally(() => {
              this.$store.state.loadingProgress = false
            })
          } else {
            this.passwordValidation.errorDisplay = true
          }
        }
      });
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    }
  },
  watch: {
    password () {
      this.passwordValidation = this.passwordValidate(this.password)
    }
  } 
}
</script>
